<script>
  import Layout from "@/Layouts/CampaignLinks.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import {page} from "@inertiajs/svelte";
  import CampaignCheckout from "@/Components/Shop/CampaignCheckout.svelte";
</script>

<Seo title="Pitch Links" />

<Layout>
  <h2>Pay for pitch link</h2>

  <div class="table-wrap">
    <table>
      <tr>
        <th>Pitch name</th>
        <td>{$page.props.campaign_link?.title}</td>
      </tr>
      <tr>
        <th>Offer ($)</th>
        <td>{$page.props.campaign_link?.amount}</td>
      </tr>
      <tr>
        <th>Max spend ($)</th>
        <td>{$page.props.campaign_link?.spend_limit}</td>
      </tr>
      <tr>
        <th>Pitch</th>
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        <td class="body">{@html $page.props.campaign_link?.pitch}</td>
      </tr>
    </table>
  </div>

  <h3>Pay ${$page.props.campaign_link?.spend_limit} now</h3>

  <CampaignCheckout
    stripe_key={$page.props.stripe_key}
    payment_methods={$page.props?.payment_methods}
    client_secret={$page.props?.client_secret}
  />
</Layout>

<style lang="postcss">
  h2 {
    @apply text-2xl font-semibold mb-4;
  }

  h3 {
    @apply text-2xl font-semibold;
  }

  .table-wrap {
    @apply py-2 px-4 border border-grey-200 rounded-lg mb-12;
  }

  table {
    @apply min-w-full divide-y divide-grey-200;

    th, td {
      @apply py-3 px-2 text-left text-sm text-grey-800 leading-snug align-top;
    }

    th {
      @apply font-semibold w-40;
    }

    td {
      @apply bg-white;
    }
  }
</style>
