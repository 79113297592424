<script>
  import Layout from "@/Layouts/App.svelte"
  import {inertia, page} from "@inertiajs/svelte";
  import Profile from "@/Components/Elements/Profile.svelte";
  import Pill from "@/Components/Elements/Pill.svelte";
  import InfiniteScroll from "@/Components/Layout/InfiniteScroll.svelte";
  import {writable} from "svelte/store";
  import axios from "axios";
  import TagGroup from "@/Components/Elements/TagGroup.svelte";
  import {buildDescription} from "@/Lib/profile";
  import cn from "classnames";
  import {IconClose} from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";

  let closedLimit = 12,

   innerWidth = 0,
   panelClosed = true,

   pending_active = true;

  $: isMobile = innerWidth < 768;

  const closePanel = () => {
    panelClosed = true;
  },
   openPanel = () => {
    panelClosed = false;
  }

  if ($page.props?.pitch) {
    pending_active = $page.props?.pitch.status === 'Pending';
  }

  if ($page.props?.response) {
    pending_active = $page.props?.response.status === 'Draft';
  }

  if ($page.url.startsWith('/app/respond/p/') || $page.url.startsWith('/app/respond/r/') || $page.url.startsWith('/app/respond/c/')) {
    openPanel();
  }

  // Const draftResponses = writable($page.props?.draft_responses?.pages?.data);

  /** @type Writable<{import('@/Lib/types/models.d').Pitch[]}> pendingPitches */
  const pendingPitches = writable($page.props?.pending_pitches?.pages?.data),

  /** @type Writable<{import('@/Lib/types/models.d').Pitch[]}> closedPitches */
   closedPitches = writable($page.props?.closed_pitches?.pages?.data),

  /** @type Writable<{number}> pendingPage */
   pendingPage = writable(1),

  /** @type Writable<{number}> closedPage */
   closedPage = writable(1),

   toggleTabs = (value) => {
    pending_active = value;
  },

   updatePendingResults = async () => {
    await axios.post('/app/respond/list/pending', { pending_page: $pendingPage })
      .then((res) => {
        // $draftResponses = res.data.responses;
        $pendingPitches = [...$pendingPitches, ...res.data];
      });
  },

   updateClosedResults = async () => {
    await axios.post('/app/respond/list/closed', { closed_page: $closedPage, closed_limit: closedLimit })
      .then((res) => {
        $closedPitches = [...$closedPitches, ...res.data.pages]
      });
  },

   getStatusColour = (status) => {
    if (status === 'Accepted') {
      return 'green';
    }

    if (status === 'Rejected') {
      return 'red';
    }

    if (status === 'Expired' || status === 'Cancelled') {
      return 'grey';
    }

    if (status === 'On Hold') {
      return 'yellow';
    }
  },

   setPitchStatus = (status) => {
    if (status === 'Accepted') {
      return 'Interested';
    }
    else if (status === 'Rejected') {
      return 'Not Interested';
    }
    else if (status === 'Expired') {
      return 'Expired';
    }
    else if (status === 'Cancelled') {
      return 'Cancelled';
    }
    else if (status === 'On Hold') {
      return 'On Hold';
    }
  }
</script>

<svelte:window bind:innerWidth />

<Layout>
  <div class={cn('wrap', isMobile ? 'mobile' : '')}>
    <aside>
      <h1>Respond <span>({$page.props?.total ?? 0})</span></h1>
      <div class="tabs">
        <button type="button" class:active={pending_active} on:click={() => toggleTabs(true)}>
          Respond <span>({$page.props?.pending_pitches?.total ?? 0})</span>
        </button>
        <button type="button" class:active={!pending_active} on:click={() => toggleTabs(false)}>
          Responded <span>({$page.props?.closed_pitches?.total ?? 0})</span>
        </button>
      </div>

      {#if pending_active}
        <div class="pitches">
          {#if $pendingPitches?.length > 0}
            {#each $pendingPitches as pitch}
              <a
                use:inertia={{ only: ['pitch', 'response', 'campaign_link'] }}
                href="/app/respond/p/{pitch.id}"
                class={$page.props?.pitch?.id === pitch.id && 'active'}
                on:click={() => openPanel()}
              >
                <Profile
                  size="sm"
                  title={pitch?.seller?.name}
                  image={pitch?.seller?.avatar}
                  imageAlt={pitch?.seller?.name}
                  subImage={pitch?.seller?.org_logo}
                  subImageAlt={pitch?.seller?.org_name}
                  subTitleHtml={buildDescription(pitch?.seller?.job_title, pitch?.seller?.org_name, pitch?.seller?.department, false)}
                />
                <span>
                  <span class="amount">${pitch.request_fee}</span>
                </span>
              </a>
            {/each}

            <InfiniteScroll
              hasMore={$pendingPitches.length !== $page.props?.pending_pitches?.total}
              threshold={100}
              on:loadMore={() => {$pendingPage++; updatePendingResults()}}
            />
          {:else}
            <p>No pending pitches</p>
          {/if}
        </div>
      {/if}

      {#if !pending_active}
        <div class="pitches">
          {#if $closedPitches?.length > 0}
            {#each $closedPitches as pitch}
              <a
                use:inertia={{ only: ['pitch', 'response', 'campaign_link', 'meeting'] }}
                href="/app/respond/p/{pitch.id}"
                class={$page.props?.pitch?.id === pitch.id && 'active'}
                on:click={() => openPanel()}
              >
                <Profile
                  size="sm"
                  title={pitch?.seller?.name}
                  image={pitch?.seller?.avatar}
                  imageAlt={pitch?.seller?.name}
                  subImage={pitch?.org?.logo}
                  subImageAlt={pitch?.org?.name}
                  subTitleHtml={buildDescription(pitch?.seller?.job_level, pitch?.seller?.org_name, pitch?.seller?.department, false)}
                />
                <span>
                  <span class="amount">${pitch?.fee}</span>
                  <Pill
                    color={getStatusColour(pitch?.status)}
                    title={setPitchStatus(pitch?.status)}
                  />
                </span>
              </a>
            {/each}

            <InfiniteScroll
              hasMore={$closedPitches.length !== $page.props?.closed_pitches?.total}
              threshold={100}
              on:loadMore={() => {$closedPage++; updateClosedResults()}}
            />
          {:else}
            <p>No pitches found</p>
          {/if}
        </div>
      {/if}
    </aside>

    <div class={cn('content', isMobile ? 'mobile' : '', panelClosed ? '' : 'open')}>
      <button type="button" class:active={isMobile} on:click={() => closePanel()}>
        <Icon data={IconClose} size="lg" />
      </button>

      {#if $page.props?.pitch}
        <div>
          <div class="header">
            <div class="buyer">
              <Profile
                title={$page.props?.pitch?.seller_name}
                href="/@{$page.props?.pitch?.seller_slug}"
                image={$page.props?.pitch?.seller_avatar}
                imageAlt={$page.props?.pitch?.seller_name}
                subImage={$page.props?.pitch?.org_logo}
                subImageAlt={$page.props?.pitch?.org_name}
                subTitleHtml={buildDescription($page.props?.pitch?.seller_title, $page.props?.pitch?.org_name, $page.props?.pitch?.seller_department, $page.props?.pitch?.seller_focuses)}
              >
                {#if $page.props?.pitch?.seller_focuses}
                  <TagGroup tags={$page.props?.pitch?.seller_focuses} />
                {/if}
              </Profile>
            </div>

            <div class="meta">
              <div class="amount">${$page.props?.pitch.request_fee}</div>
              <div class="created"><strong>Created:</strong> {$page.props?.pitch.created_at}</div>
              {#if $page.props?.pitch.expires_at}
                <div class="expires"><strong>Expires:</strong> {$page.props?.pitch.expires_at}</div>
              {/if}
            </div>
          </div>

          <slot />
        </div>
      {/if}

      {#if $page.props?.campaign_link}
        <div>
          <div class="header">
            <div class="buyer">
              <Profile
                title={$page.props?.campaign_link?.seller?.name}
                href="/@{$page.props?.campaign_link?.seller?.slug}"
                image={$page.props?.campaign_link?.seller?.avatar}
                imageAlt={$page.props?.campaign_link?.seller?.name}
                subImage={$page.props?.campaign_link?.seller?.org_logo}
                subImageAlt={$page.props?.campaign_link?.seller?.org_name}
                subTitleHtml={$page.props?.campaign_link?.seller?.description}
              >
                {#if $page.props?.campaign_link?.seller?.tags}
                  <TagGroup tags={$page.props?.campaign_link?.seller?.tags.map(x => ({ title: x.name }))} />
                {/if}
              </Profile>
            </div>

            <div class="meta">
              <div class="amount">${$page.props?.campaign_link.amount}</div>
            </div>
          </div>

          <slot />
        </div>
      {/if}

      {#if $page.props?.response}
        <div>
          <div class="header">
            <div class="buyer">
              <Profile
                title={$page.props?.response?.seller?.name}
                href="/@{$page.props?.response?.seller?.slug}"
                image={$page.props?.response?.seller?.avatar}
                imageAlt={$page.props?.response?.seller?.name}
                subImage={$page.props?.response?.seller?.org_logo}
                subImageAlt={$page.props?.response?.seller?.org_name}
                subTitleHtml={$page.props?.response?.seller?.description}
              >
                {#if $page.props?.response?.seller?.tags}
                  <TagGroup tags={$page.props?.response?.seller?.tags.map(x => ({ title: x.name }))} />
                {/if}
              </Profile>
            </div>

            <div class="meta">
              <div class="amount">${$page.props?.response?.fee}</div>
              <div class="created"><strong>Created:</strong> {$page.props?.response.created_at}</div>
            </div>
          </div>

          <slot />
        </div>
      {/if}
    </div>
  </div>
</Layout>

<style lang="postcss">
  .wrap {
    @apply flex-1 md:flex min-h-full;

    .content > button {
      @apply hidden;
    }

    &.mobile {
      @apply relative;

      .pitches {
        @apply pb-[100%];
      }

      .content {
        @apply top-auto right-auto w-full bg-white shadow-2xl shadow-black h-full;

        &.mobile {
          @apply fixed -z-10 top-28 right-0 opacity-0;
        }

        &.open {
          @apply opacity-100 z-10;
        }

        & > button {
          @apply block absolute top-3 left-2 z-10;
        }

        & > div {
          @apply overflow-scroll h-[calc(100%-5rem)] pb-8;
        }
      }
    }
  }

  h1 {
    @apply text-2xl mb-8 ml-4;
  }

  aside {
    @apply md:w-80 md:shrink-0 py-8 lg:py-6 bg-grey-100 md:min-h-screen;
  }

  .tabs {
    @apply bg-blue-50 rounded-lg p-1 flex mx-3 md:mx-2;

    button {
      @apply inline-block rounded-lg w-1/2 py-2.5 text-sm font-semibold transition-colors duration-300;

      &.active {
        @apply bg-blue text-white;

        &:hover {
          @apply bg-blue text-white;
        }

        span {
          @apply text-white;
        }
      }

      &:hover {
        @apply bg-blue-100 text-black;
      }

      span {
        @apply text-black;
      }
    }
  }

  .pitches {
    @apply flex flex-col gap-2 mt-2 px-4 md:px-2 text-center md:overflow-y-scroll md:overscroll-y-contain md:max-h-[calc(100vh-250px)] lg:max-h-[calc(100vh-160px)];

    a {
      @apply w-full px-2 py-3 border-b border-grey-200 flex flex-row md:flex-col lg:flex-row justify-between gap-2 no-underline;

      &:hover,
      &.active {
        @apply bg-white/50 rounded-lg border-grey-100;
      }

      &.active {
        @apply bg-white;
      }

      & > span {
        @apply flex flex-col items-end;
      }

      .amount {
        @apply text-violet text-xl font-semibold mb-2;
      }
    }
  }

  .content {
    @apply w-full md:border-l border-grey-200 px-6 py-8 lg:py-6 flex flex-col md:overflow-y-scroll md:overscroll-y-contain md:max-h-[calc(100vh-90px)] lg:max-h-screen;

    .header {
      @apply flex justify-between gap-4 pb-8 border-b border-grey-200 mb-6;
    }

    .meta {
      @apply flex flex-col items-end justify-between;
    }

    .created {
      @apply text-xs text-right mt-3 text-grey-600;
    }

    .expires {
      @apply text-xs text-right mt-1 text-red-400;
    }

    .amount {
      @apply text-violet font-semibold text-3xl;
    }

    p {
      span {
        @apply text-violet font-semibold;
      }
    }
  }
</style>
