<script>
  import Layout from "@/Layouts/CampaignLinks.svelte"
  import Seo from "@/Components/Seo/Index.svelte";
  import {page} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import copy from "@/Lib/utils/copy.js";
  import {IconCopy} from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";

  const emailCopy = `Hey {First Name},

We know cold emails can be annoying. Rather than pitching you over email, interrupting you 100 times, and asking you to respond for free, I’m offering you $${$page.props?.campaign_link?.amount} to answer my prospecting here.

No meeting required, you just have to answer.

View my pitch: ${$page.props?.campaign_link?.url}

Thanks,

${$page.props?.campaign_link?.seller?.name}

P.s. Want to know about Pitchfire first. This video explains it more: https://youtu.be/WYOHrdxlovM
  `,

   subjectCopy = `${$page.props?.campaign_link?.seller?.org_name} is offering you $${$page.props?.campaign_link?.amount}`;
</script>

<Seo title={`Pitch: ${$page.props?.campaign_link?.title}`} />

<Layout>
  <h2>Stats</h2>

  <div class="table-wrap">
    <table>
      <tr>
        <th>Budget</th>
        <td>${$page.props?.campaign_link?.spent} spent / ${$page.props?.campaign_link?.spend_limit} limit</td>
      </tr>
      <tr>
        <th>Clicks</th>
        <td>{$page.props?.campaign_link?.clicks}</td>
      </tr>
      <tr>
        <th>Responses</th>
        <td>{$page.props?.campaign_link?.responses}</td>
      </tr>

       {#if $page.props?.campaign_link?.emails_total > 0}
        <tr>
          <th>Emails sent</th>
          <td>{$page.props?.campaign_link?.emails_sent} / {$page.props?.campaign_link?.emails_total}</td>
        </tr>
       {/if}
    </table>
  </div>

  <h2>Overview</h2>

  <div class="table-wrap">
    <table>
      <tr>
        <th>Pitch name</th>
        <td>{$page.props?.campaign_link?.title}</td>
      </tr>
      <tr>
        <th>Offer ($)</th>
        <td>{$page.props?.campaign_link?.amount}</td>
      </tr>
      <tr>
        <th>Max spend ($)</th>
        <td>{$page.props?.campaign_link?.spend_limit}</td>
      </tr>
      <tr>
        <th>Pitch</th>
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        <td class="body">{@html $page.props?.campaign_link?.pitch}</td>
      </tr>
      <tr>
        <th>Url</th>
        <td>
          <button class="url" on:click|preventDefault={() => copy($page.props?.campaign_link?.url)}>
            <span>{$page.props?.campaign_link?.url.replace('https://', '')}</span>
            <Icon data={IconCopy} size="md" />
          </button>
        </td>
      </tr>
      <tr>
        <th>Subject line</th>
        <td>
          <button class="url" on:click|preventDefault={() => copy(subjectCopy)}>
            <span>{subjectCopy}</span>
            <Icon data={IconCopy} size="md" />
          </button>
        </td>
      </tr>
      <tr>
        <th>Email</th>
        <td>
          <div class="email-body">{emailCopy}</div>
          <button class="url" on:click|preventDefault={() => copy(emailCopy)}>
            <span>Copy email</span>
            <Icon data={IconCopy} size="md" />
          </button>
        </td>
      </tr>
      <tr>
        <th>Status</th>
        <td>{$page.props?.campaign_link?.status}</td>
      </tr>
      <tr>
        <th>Created</th>
        <td>{$page.props?.campaign_link?.created_at}</td>
      </tr>
      <tr>
        <th>Updated</th>
        <td>{$page.props?.campaign_link?.updated_at}</td>
      </tr>
    </table>
  </div>

  <div class="actions">
    <Button type="button" color="secondary" href="/app/pitch-links/{$page.props?.campaign_link?.id}/edit" dynamic>
      Edit
    </Button>
  </div>
</Layout>

<style lang="postcss">
  h2 {
    @apply text-2xl font-semibold mb-4;
  }

  .table-wrap {
    @apply py-2 px-4 border border-grey-200 rounded-lg mb-12;
  }

  table {
    @apply min-w-full divide-y divide-grey-200;

    th, td {
      @apply py-3 px-2 text-left text-sm text-grey-800 leading-snug align-top;
    }

    th {
      @apply font-semibold w-40;
    }

    td {
      @apply bg-white;
    }
  }

  .email-body {
    @apply whitespace-pre-line mb-4;
  }

  .actions {
    @apply flex justify-end;
  }

  .url {
    @apply flex justify-start items-center text-sm gap-2;

    span {
      @apply text-violet
    }
  }
</style>
