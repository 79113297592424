<script>
  import Layout from '@/Layouts/Page.svelte'
  import Seo from "@/Components/Seo/Index.svelte";
  import {page} from "@inertiajs/svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import {
    IconCoins,
    IconComment,
    IconFlame,
    IconLinkedIn,
    IconWWW,
  } from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";
  import FAQ from "@/Components/Sections/FAQ.svelte";
  import Works from "@/Components/Sections/Works.svelte";
</script>

<Seo title="Review Pitch" />

<Layout nav footer class="!max-w-6xl !pt-0">
  <div class="wrap">
    <div class="profile">
      <div class="employee">
        <div class="head">
          {#if $page.props?.profile?.avatar}
            <a class="p-img" href={`/@${$page.props?.profile?.slug}`} target="_blank" rel="noopener noreferrer">
              <img decoding="async" src={$page.props?.profile?.avatar} alt={$page.props?.profile?.name} />
            </a>
          {/if}

          <div class="p-info">
            {#if $page.props?.profile?.name}
              <h1>{$page.props?.profile?.name}</h1>
            {/if}

            {#if $page.props?.profile?.job_level || $page.props?.profile?.job_title}
              <p>{$page.props?.profile?.job_title ?? $page.props?.profile?.job_level}{$page.props?.profile?.org_name ? ` at ${$page.props?.profile?.org_name}` : ''}</p>
            {:else}
              <p>{$page.props?.profile?.org_name ?? ''}</p>
            {/if}
          </div>
        </div>

        {#if $page.props?.profile?.about}
          <div class="field">
            <h3>About me</h3>
            <p>{$page.props?.profile?.about}</p>
          </div>
        {/if}

        <div class="mobile-cta">
          <div>
            <h4>Review my pitch.</h4>
            <p>You get paid <span>${$page.props?.campaign_link?.amount}</span> 😊</p>
          </div>

          <div class="actions-wrap">
            <div class="actions">
              <a class="btn-green" href="#pitch">Review</a>
            </div>
          </div>
        </div>

        {#if $page.props?.profile?.linkedin_url}
          <div class="field li">
            <a href={$page.props?.profile?.linkedin_url} target="_blank" rel="noopener noreferrer">
              <Icon size="xxl" data={IconLinkedIn} />
            </a>

            <p><a href={$page.props?.profile?.linkedin_url} target="_blank" rel="noopener noreferrer">
              <span>in/{$page.props?.profile?.linkedin_url.replace("https://www.linkedin.com/in/", "").replace("http://www.linkedin.com/in/", "")}</span>
            </a></p>
          </div>
        {/if}

        {#if $page.props?.profile?.job_title || $page.props?.profile?.job_level}
          <div class="field">
            <h3>My Position in the Company</h3>
            <p>{$page.props?.profile?.job_title ? $page.props?.profile?.job_title : $page.props?.profile?.job_level}</p>
          </div>
        {/if}

        {#if $page.props?.profile?.department}
          <div class="field">
            <h3>My Department</h3>
            <p>{$page.props?.profile?.department}</p>
          </div>
        {/if}
      </div>

      <div class="company">
        <div class="wrap-info">
          {#if $page.props?.profile?.org_logo || $page.props?.profile?.org_name}
            <a href={`/org/${$page.props?.profile?.org_slug}`} target="_blank" rel="noopener noreferrer">
              <img
                decoding="async"
                src={$page.props?.profile?.org_logo}
                alt={$page.props?.profile?.org_name}
              />
            </a>
          {/if}

          {#if $page.props?.profile?.org_name}
            <p><a href={`/org/${$page.props?.profile?.org_slug}`} target="_blank" rel="noopener noreferrer">{$page.props?.profile?.org_name}</a></p>
          {/if}
        </div>

        {#if $page.props?.profile?.org_country}
          <div class="field">
            <h3>Headquarters</h3>
            <p>{$page.props?.profile?.org_city ? $page.props?.profile?.org_city + ', ' + $page.props?.profile?.org_country : $page.props?.profile?.org_country}</p>
          </div>
        {/if}

        <div class="field">
          <a class="icon-link" href="https://{$page.props?.profile?.org_website}" target="_blank" rel="noopener noreferrer">
            <Icon data={IconWWW} size="xxl" />
            <span>{$page.props?.profile?.org_website}</span>
          </a>
        </div>

        {#if $page.props?.profile?.org_linkedin}
          <div class="field">
            <a class="icon-link" href={$page.props?.profile?.org_linkedin} target="_blank" rel="noopener noreferrer">
              <Icon data={IconLinkedIn} size="xxl" />
              <span>{$page.props?.profile?.org_linkedin.replace('http://www.linkedin.com/company/', '')}</span>
            </a>
          </div>
        {/if}
      </div>
    </div>

    <div class="pitch" id="pitch">
      <div class="header">
        <div>
          <h4>Review my pitch.</h4>
          <p>You get paid <span>${$page.props?.campaign_link?.amount}</span> 😊</p>
        </div>

        <div class="actions-wrap">
          <div class="actions">
            <Button color="secondary" href="/app/respond/c/{$page.props?.campaign_link?.id}/respond" dynamic>Respond</Button>
          </div>
        </div>
      </div>

      <!-- eslint-disable-next-line svelte/no-at-html-tags -->
      <div class="body">{@html $page.props?.campaign_link?.pitch}</div>

      <div class="actions-wrap">
        <div class="actions">
          <Button color="secondary" href="/app/respond/c/{$page.props?.campaign_link?.id}/respond" dynamic>Respond</Button>
        </div>

        <p>You get paid <span>${$page.props?.campaign_link?.amount}</span> 😊</p>
      </div>
    </div>
  </div>

  <section id="how-it-works" class="how">
    <Works
      title="How does Pitchfire work?"
      cards={[{
        image: IconComment,
        title: "Answer this pitch truthfully",
        description:
          "<p>It doesn’t matter if the answer is yes or no.</p>" +
          "<p>If you are interested, you can book a meeting right on their calendar.</p>" +
          "<p>If you aren’t, just be kind and let them know why.</p>",
      }, {
        image: IconCoins,
        title: "Get paid for answering the pitch",
        description: "<p>This company pitching you believes it’s more ethical to pay you for your attention and response on their sales pitch, then spam you cold calls, emails, and LinkedIn messages.</p>" +
          "<p>Remember they are paying for an answer, so try and be as helpful as possible.</p>",
      }, {
        image: IconFlame,
        title: "Join Pitchfire to withdraw money",
        description:
          "<p>Pitchfire is the world’s leading paid prospecting platform.</p>" +
          "<p>All people who join Pitchfire answer sales pitches from businesses for money, and securely transfer them to their bank.</p>" +
          "<p>All members list themselves in our B2B marketplace, allowing other businesses to pitch them for responding.</p>" +
          "<p>We’ll ask you to join to receive the money.</p>",
      }]}
    />
    <FAQ class="mt-12" />
  </section>
</Layout>

<style lang="postcss">
  .wrap {
    @apply relative mb-20 min-h-full lg:grid lg:grid-cols-5 gap-0;
  }

  .mobile-cta {
    @apply flex sm:hidden mt-4 mb-8 bg-white px-4 py-3 rounded-lg gap-4 justify-between;

    p {
      @apply m-0;
    }
  }

  .profile {
    @apply flex flex-col sm:flex-row lg:flex-col py-5 px-5 md:px-7 relative bg-grad-tertiary gap-4 lg:col-span-2;

    .employee {
      @apply w-full sm:w-3/5 md:w-2/3 lg:w-full;

      .head {
        @apply flex flex-row items-center gap-4 sm:gap-6;
      }
    }

    .p-info {
      h1 {
        @apply text-3xl text-grey-800 mb-1;

        a {
          @apply hover:underline no-underline;
        }
      }

      p {
        @apply text-sm text-grey mb-1;
      }
    }

    .p-img img {
      @apply w-20 h-20 border-grey-200 rounded-lg object-cover object-center;
    }

    .li {
      @apply w-full flex items-center gap-2;

      img {
        @apply w-10 h-10 object-contain object-center mr-2 rounded-lg;
      }

      p {
        @apply mb-0 text-sm;
      }
    }

    .field {
      @apply mt-5;

      h3 {
        @apply text-xs text-black mb-1 uppercase;
      }

      p {
        @apply text-sm text-grey-800 font-normal mb-0 mt-2;
      }
    }

    .company {
      @apply mt-4 sm:mt-8 lg:mt-12;
    }

    .wrap-info {
      @apply w-full flex items-center gap-2;

      img {
        @apply w-10 h-10 object-contain object-center mr-2 rounded-lg;
      }

      p {
        @apply mb-0 text-sm;
      }
    }

    .icon-link {
      @apply flex items-center text-sm no-underline hover:underline;

      span {
        @apply ml-2 block;
      }
    }
  }

  .header {
    @apply flex justify-between;

    h4 {
      @apply font-semibold inline-block leading-snug text-base;
    }

    p {
      @apply m-0;

      span {
        @apply text-violet font-semibold;
      }
    }
  }

  .pitch {
    @apply bg-white px-6 py-5 md:px-10 md:py-8 lg:col-span-3;

    .body {
      @apply border border-grey-200 rounded-lg p-4 text-grey-700 text-base my-4;
    }

    .actions-wrap {
      @apply flex flex-col items-end;

      & > .actions {
        @apply mb-2;
      }

      & > p {
        @apply m-0;

        span {
          @apply text-violet font-semibold;
        }
      }
    }
  }
</style>
