<script>
  import { inertia, page } from "@inertiajs/svelte";
  import Layout from "@/Layouts/App.svelte";
  import Seo from "@/Components/Seo/Index.svelte";
  import {IconLinkedIn} from "@/Components/Assets/Icons/index.js";
  import Icon from "@/Components/Assets/Icon.svelte";
  import Breadcrumb from "@/Components/Elements/Breadcrumb.svelte";
  import Works from "@/Components/Sections/Works.svelte";
  import FAQ from "@/Components/Sections/FAQ.svelte";
  import Button from "@/Components/Buttons/Button.svelte";
  import MarketplaceResult from "@/Components/Elements/MarketplaceResult.svelte";

  const focusedName = (focuses) => {
    if (focuses == null) {
      return null;
    }

    return focuses.map((focus) => focus.name).join(", ");
  };
</script>

<Layout>
  <Seo
    title={`Pitch ${$page.props?.profile?.name}`}
    breadcrumbs={[
      { name: 'Marketplace', slug: '/app/marketplace' },
      { name: $page.props?.profile?.name },
    ]}
    metaDescription={$page.props?.profile?.about ? $page.props?.profile?.about : `Pitch ${$page.props?.profile?.name}`}
    ogImage={{
      url: `${$page.props?.common.app_url  }/og-images/users/${  $page.props?.profile?.slug  }.png`,
      alt: $page.props?.profile?.name,
    }}
    twitterImage={{
      url: `${$page.props?.common.app_url  }/og-images/users/${  $page.props?.profile?.slug  }.png`,
      alt: $page.props?.profile?.name,
    }}
  />

  <section>
    <Breadcrumb
      crumbs={[
        { title: "Menu", url: "/" },
        { title: "Buyer Marketplace", url: "/app/marketplace" },
        { title: $page.props?.profile?.name },
      ]}
    />

    <div class="profile">
      {#if $page.props?.profile?.avatar}
        <a class="p-img" href={`/@${$page.props?.profile?.slug}/pitch`} use:inertia>
          <img decoding="async" src={$page.props?.profile?.avatar} alt={$page.props?.profile?.name} />
        </a>
      {/if}

      <div class="p-info">
        {#if $page.props?.profile?.name}
          <h1>{$page.props?.profile?.name}</h1>
        {/if}

        {#if $page.props?.profile?.job_level || $page.props?.profile?.job_title}
          <p>{$page.props?.profile?.job_level ?? $page.props?.profile?.job_title}{$page.props?.profile?.org_name ? ` at ${$page.props?.profile?.org_name}` : ''}</p>
        {:else}
          <p>{$page.props?.profile?.org_name ?? ''}</p>
        {/if}
      </div>
    </div>

    {#if $page.props?.profile?.response_status}
      <div class="status">
        <div class="field">
          <h3>Response Status</h3>
          <p>{$page.props?.profile?.response_status}</p>
        </div>
      </div>
    {/if}

    <div class="detail">
      <div class="company">
        <h2>Company</h2>

        <div class="wrap-info">
          {#if $page.props?.profile?.org_logo || $page.props?.profile?.org_name || $page.props?.profile?.buyers}
            <a href={`/app/marketplace/org/${$page.props?.profile?.org_slug}`} use:inertia>
              <img
                decoding="async"
                src={$page.props?.profile?.org_logo}
                alt={$page.props?.profile?.org_name}
              />
            </a>

            {#if $page.props?.profile?.org_name}
              <p><a href={`/app/marketplace/org/${$page.props?.profile?.org_slug}`} use:inertia>{$page.props?.profile?.org_name}</a></p>
            {/if}
          {/if}
        </div>

        <div class="field">
          {#if $page.props?.profile?.job_title || $page.props?.profile?.job_level}
            <h3>My Position in the Company</h3>
            <p>{$page.props?.profile?.job_title ? $page.props?.profile?.job_title : $page.props?.profile?.job_level}</p>
          {/if}

          {#if $page.props?.profile?.department}
            <div class="field">
              <h3>My Department</h3>
              <p>{$page.props?.profile?.department}</p>
            </div>
          {/if}

          {#if $page.props?.profile?.focuses?.length > 0}
            <div class="field">
              <h3>Focused on</h3>
              <p>{focusedName($page.props?.profile?.focuses)}</p>
            </div>
          {/if}

          {#if $page.props?.profile?.vendors}
            <div class="field">
              <h3>Vendors we use</h3>
              <p>{$page.props?.profile?.vendors}</p>
            </div>
          {/if}
        </div>
      </div>

      {#if $page.props?.profile?.linkedin_url || $page.props?.profile?.home_base || $page.props?.profile?.timezone || $page.props?.profile?.preferred_employment || $page.props?.profile?.about}
        <div class="personal">
          <h2>Personal</h2>

          {#if $page.props?.profile?.linkedin_url}
            <div class="wrap-info">
              <a href={$page.props?.profile?.linkedin_url} target="_blank" rel="noopener noreferrer">
                <Icon size="xxl" data={IconLinkedIn} />
              </a>
              <p><a href={$page.props?.profile?.linkedin_url} target="_blank" rel="noopener noreferrer">
                {$page.props?.profile?.linkedin_url.replace("http://www.linkedin.com/in/", "")}
              </a>
              </p>
            </div>
          {/if}

          <div class="wrap-field-two">
            {#if $page.props?.profile?.country}
              <div class="personal-details field">
                <h3>My Home base</h3>
                <p>{$page.props?.profile?.state ? `${$page.props?.profile?.state  }, ${  $page.props?.profile?.country}` : $page.props?.profile?.country}</p>
              </div>
            {/if}

            {#if $page.props?.profile?.timezone}
              <div class="personal-details field">
                <h3>Timezone</h3>
                <p>{$page.props?.profile?.timezone}</p>
              </div>
            {/if}
          </div>

          {#if $page.props?.profile?.preferred_employment}
            <div class="personal-details field">
              <h3>How I like to work</h3>
              <p>{$page.props?.profile?.preferred_employment}</p>
            </div>
          {/if}

          <div class="personal-details field">
            {#if $page.props?.profile?.about}
              <h3>About me</h3>
              <p>{$page.props?.profile?.about}</p>
            {/if}
          </div>
        </div>
      {/if}
    </div>
  </section>

  <section id="pitch" class="bg-blue-800 py-5 px-6 lg:px-10 flex flex-col sm:flex-row gap-4 sm:gap-0 items-center justify-between text-white">
    <a class="btn-red" href={`/@${$page.props?.profile?.slug}/pitch`} use:inertia>
      Pitch Me
    </a>

    <div class="hidden sm:block bg-center bg-[length:8px_11px] bg-repeat-x h-2 flex-grow mx-4 bg-[url(/assets/dash.svg)]"></div>

    {#if !$page.props?.profile?.avg_offer || $page.props?.profile?.avg_offer < $page.props?.profile?.pitch_price}
      <p class="m-0">My Minimum Offer: <strong>${$page.props?.profile?.pitch_price ?? 25}</strong></p>
    {:else}
      <p class="m-0">My Average Offer: <strong>${$page.props?.profile?.avg_offer}</strong></p>
    {/if}

    <div class="hidden sm:block bg-center bg-[length:8px_11px] bg-repeat-x h-2 flex-grow mx-4 bg-[url(/assets/dash.svg)]"></div>
    <img class="hidden sm:block w-12 rounded-lg" src={$page.props?.profile?.avatar ?? '/assets/avatar.png'} alt={$page.props?.profile?.name} />
  </section>

  {#if $page.props?.team && $page.props?.team?.data.length > 0}
    <section id="team">
      <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-4">
        {#each $page.props?.team?.data as member}
          <MarketplaceResult
            href={`/app/marketplace/@${member.slug}`}
            name={member?.name}
            image={member?.avatar ?? ''}
            imageAlt={member.name}
            title={member.job_title ?? member.job_level}
            org_name={member.org_name}
            org_url={`/app/marketplace/org/${member.org_slug}`}
            price={member.pitch_price ?? 25}
            theme="minimal"
            size="xl"
          >
            <div slot="actions" class="text-center mt-4">
              <Button dynamic size="sm" color="red" class="w-full" href={`/app/marketplace/@${member.slug}`}>Starting at ${member?.pitch_price ?? 25}</Button>
            </div>
          </MarketplaceResult>
        {/each}
      </div>

      <div class="mt-6">
        <a use:inertia href={`/app/marketplace/org/${$page.props?.profile?.org_slug}`} class="inline-flex items-center no-underline font-bold text-sm">
          {#if $page.props?.profile?.org_logo}
            <img class="w-10 block mr-4" src={$page.props?.profile?.org_logo} alt={$page.props?.profile?.org_name}>
          {/if}
          <span>{$page.props?.team?.data.length} {$page.props?.team?.data.length === 1 ? 'buyer' : 'buyers'} at {$page.props?.profile?.org_name}</span>
        </a>
      </div>
    </section>
  {/if}

  <section id="how-it-works">
    <Works title="How does Pitchfire work?" />
    <FAQ class="mt-12" />
  </section>
</Layout>

<style lang="postcss">
  section {
    @apply py-2.5 px-4 lg:px-8 mb-12 max-w-7xl;

    .profile {
      @apply flex flex-col sm:flex-row sm:items-center gap-4 sm:gap-6 mb-7 mt-8;

      .p-info {
        h1 {
          @apply text-3xl text-grey-800 mb-1;

          a {
            @apply hover:underline no-underline;
          }
        }

        p {
          @apply text-sm text-grey mb-1;
        }
      }

      .p-img img {
        @apply w-20 h-20 border-grey-200 rounded-lg object-cover object-center;
      }
    }

    .status {
      @apply mb-6;

      .field {
        @apply w-full;

        h3 {
          @apply text-xs text-black mb-1 uppercase;
        }

        p {
          @apply text-sm text-grey-800 font-normal mb-4;
        }
      }
    }

    .detail {
      @apply flex w-full flex-col md:flex-row gap-8;

      .company {
        @apply w-full md:w-5/12;
      }

      .personal {
        @apply w-full md:w-7/12;
      }

      .wrap-field-two {
        @apply sm:flex w-full;

        .field {
          @apply w-1/2;
        }
      }

      h2 {
        @apply font-semibold text-base leading-5 text-grey-800 mb-7;
      }

      .wrap-info {
        @apply w-full flex items-center mb-5 gap-2;

        img {
          @apply w-10 h-10 object-contain object-center mr-2 rounded-lg;
        }

        p {
          @apply mb-0 text-sm;
        }
      }

      .field {
        h3 {
          @apply text-xs text-black mb-1 uppercase;
        }

        p {
          @apply text-sm text-grey-800 font-normal mb-5;
        }
      }
    }
  }
</style>
