<script>
  import Layout from "@/Layouts/Respond.svelte"

  import Seo from "@/Components/Seo/Index.svelte";
  import {page} from "@inertiajs/svelte";
  import Pill from "@/Components/Elements/Pill.svelte";
  import Button from "@/Components/Buttons/Button.svelte";

  const getStatusColour = (status) => {
    if (status === 'Accepted') {
      return 'green';
    }

    if (status === 'Rejected') {
      return 'red';
    }

    if (status === 'Expired' || status === 'Cancelled') {
      return 'grey';
    }

    if (status === 'On Hold') {
      return 'yellow';
    }
  },

   setPitchStatus = (status) => {
    if (status === 'Accepted') {
      return 'Interested';
    }
    else if (status === 'Rejected') {
      return 'Not Interested';
    }
    else if (status === 'Expired') {
      return 'Expired';
    }
    else if (status === 'Cancelled') {
      return 'Cancelled';
    }
    else if (status === 'On Hold') {
      return 'On Hold';
    }
  }
</script>

<Seo title={`$${$page.props?.pitch?.request_fee} Pitch from ${$page.props?.pitch?.seller_name}`} />

<Layout>
  <div class="pitch">
    <h4>Review Pitch</h4>
    <div class="body">{$page.props?.pitch?.body}</div>
  </div>

  {#if $page.props?.pitch?.status === 'Pending'}
    <div class="actions-wrap">
      {#if $page.props?.pitch.seller_deleted_at == null}
        <div class="actions">
          <Button color="secondary" href="/app/respond/p/{$page.props?.pitch.id}/respond" dynamic>Respond</Button>
        </div>
      {/if}

      {#if $page.props?.pitch?.is_paid}
        <p>You get paid <span>${$page.props?.pitch?.request_fee} either way</span> 😊</p>
      {/if}
    </div>
  {:else}
    <div class="response">
      <div class="title">
        {#if $page.props?.pitch?.status === 'Expired'}
          {#if $page.props?.pitch?.expires_at}
            <span>You did not respond in time</span>
          {:else}
            <span>This pitch is no longer available</span>
          {/if}
        {:else}
          <span>Your response to {$page.props?.pitch?.seller_name}</span>
        {/if}

        <Pill
          color={getStatusColour($page.props?.pitch?.status)}
          title={setPitchStatus($page.props?.pitch?.status)}
        />
      </div>

      {#if $page.props?.pitch?.status !== 'Expired'}
        {#if $page.props?.pitch?.reason}
          <div class="reason">
            {#if $page.props?.pitch?.status === 'Rejected'}
              <span>{$page.props?.pitch?.reason}</span>
            {:else}
              <span>I am interested in meeting with you and {$page.props?.pitch?.org_name} because...</span>
            {/if}
          </div>
        {/if}

        {#if $page.props?.pitch?.response}
          <div class="body">{$page.props?.pitch?.response}</div>
        {/if}

        {#if $page.props?.meeting}
          <div class="title">Meeting Details</div>
          <div class="meeting">
            <div>
              <div class="heading">Date & Time</div>
              <div class="date">{$page.props?.meeting?.meeting_datetime}</div>
              <div class="heading">Time Zone</div>
              <div class="timezone">{$page.props?.meeting?.meeting_timezone}</div>

              <div class="links">
                {#if $page.props?.pitch?.meeting_link}
                  <a href={$page.props?.pitch?.meeting_link} target="_blank" rel="noopener noreferrer">Meeting Link</a>
                {/if}

                {#if $page.props?.meeting?.event_link}
                  <a href={$page.props?.meeting?.event_link} target="_blank" rel="noopener noreferrer">View in Calendar</a>
                {/if}
              </div>
            </div>
          </div>
        {/if}
      {/if}
    </div>
  {/if}
</Layout>

<style lang="postcss">
  .pitch {
    @apply flex flex-col relative mb-4;

    h4 {
      @apply text-base mb-4;
    }

    .body {
      @apply whitespace-pre-line border p-4 rounded-xl;
    }
  }

  .actions-wrap {
    @apply mt-4 m-0 md:mb-16 flex flex-col items-end mb-20;

    & > .actions {
      @apply mb-2;
    }

    & > p {
      @apply m-0;
    }
  }

  p {
    span {
      @apply text-violet font-semibold;
    }
  }

  .response {
    @apply mt-8;

    .title {
      @apply text-base font-semibold mb-2;
    }

    .reason {
      @apply text-sm font-semibold mb-2;
    }

    .body {
      @apply whitespace-pre-line text-sm mb-6;
    }

    .meeting {
      @apply p-4 bg-grey-100 rounded-xl mt-2 flex justify-between items-end;

      .heading {
        @apply text-sm font-semibold mb-2;
      }

      .date,
      .timezone {
        @apply text-lg leading-none;
      }

      .date {
        @apply mb-4;
      }

      .links {
        @apply mt-4 flex gap-6;

        a {
          @apply font-semibold;
        }
      }
    }
  }
</style>
